import React from "react"
import { graphql } from "gatsby"

// import Img from "gatsby-image"

import Seo from "../components/SEO"
import Footer from "../components/footer"
import Wave from "../components/wave"

const CaseStudy = ({ data, pageContext: { locale }, location }) => {
  const caseStudy = data.allPrismicCasestudy.edges[0].node
  return (
    <>
      <Seo
        title={
          caseStudy.data.title.text +
          " | " +
          data.allPrismicGlobal.edges[0].node.data.use_case +
          ", Josselin CAER"
        }
        desc={
          caseStudy.data.seo_blog_description
            ? caseStudy.data.seo_blog_description
            : ""
        }
        pathname={location.pathname}
        locale={locale}
      />
      <Wave title={caseStudy.data.title.text} />
      {/* <Img
        fluid={caseStudy.data.image.fluid}
        alt={caseStudy.data.image.alt}
        className="mx-10 max-w-3xl mb-10 md:mx-auto"
      /> */}
      <article className="relative max-w-3xl mx-auto font-serif z-10 pt-10 md:pt-0  ">
        {caseStudy.data.body
          .filter(type => type.slice_type === "content")
          .map((content, i) => (
            <div
              key={i}
              className="richtext"
              dangerouslySetInnerHTML={{
                __html: content.primary.richtext.html,
              }}
            />
          ))}
      </article>
      <Footer posts={data.allPrismicBlogpost} />
    </>
  )
}

export default CaseStudy

export const pageQuery = graphql`
  query($uid: String, $locale: String) {
    allPrismicCasestudy(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          id
          tags
          uid
          data {
            title {
              html
              text
            }
            image {
              alt
              fluid(maxWidth: 600) {
                ...GatsbyPrismicImageFluid
              }
            }
            body {
              ... on PrismicCasestudyDataBodyContent {
                id
                primary {
                  richtext {
                    html
                  }
                }
                slice_type
                slice_label
              }
            }
          }
        }
      }
    }

    allPrismicGlobal(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          lang
          data {
            back_to_home
            use_case
          }
        }
      }
    }

    allPrismicBlogpost(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          uid
          lang
          data {
            title {
              text
              html
            }
            body {
              ... on PrismicBlogpostDataBodyContent {
                id
                slice_type
                slice_label
                primary {
                  richtext {
                    text
                    html
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
